import styled from '@emotion/styled';
import { COUNTRY } from '../../../configs/env';

export const BenefitsSectionWrapper = styled.div`
  @media screen and (max-width: 768px) {
    .benefits-section {
      padding: 40px 20px;
      background-image: url('/new-images/benefits-section-mobile.png');
      background-size: 100% auto;

      .padding-20 {
        padding: 20px;
      }

      button {
        height: 50px;
        font-size: 16px;
        border-radius: 15px;
        margin-bottom: 40px;
      }
    }

    h6 {
      font-size: 20px;
      margin-top: 10px;
    }

    h2 {
      font-size: 28px;
      margin-bottom: 20px;
    }

    h4 {
      margin-bottom: 20px;
      font-size: 12px;
    }

    .benefits-cards {
      padding: 0 !important;
      width: 100%;

      img:first {
        margin-top: 0;
      }

      img {
        margin-top: 20px;
        width: 46px;
        margin-bottom: 0;
      }
    }

    .flex-wrapper {
      display: block;

      p {
        font-size: 14px;
        opacity: 0.8;
      }
    }

    .mobile-margin-bottom-20 {
      margin-bottom: 20px;
    }

    .benefits-flex-wrapper {
      flex-direction: column-reverse;
    }
  }

  @media screen and (max-width: 1024px) {
    .benefits-flex-wrapper {
      flex-wrap: wrap;
    }

    .benefits-button {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 370px) {
    .benefits-section {
      h2 {
        font-size: 24px;
      }
    }
  }

  @media screen and (min-width: 450px) and (max-width: 769px) {
    .benefits-cards {
      width: 50%;
    }

    .benefits-card {
      padding: 20px 40px;
    }

    .benefits-flex-wrapper {
      flex-direction: row;
    }

    .benefits-button {
      margin-bottom: 0 !important;
    }

    .benefits-section {
      padding: 60px 50px;
    }
  }

  @media screen and (min-width: 769px) and (max-width: 1399px) {
    .benefits-section {
      padding: 120px 50px;

      h6 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
        opacity: 0.8;
      }
    }

    .benefits-card {
      width: 250px;
      padding: 30px;
    }
  }

  @media screen and (min-width: 1400px) {
    .benefits-section {
      padding: 120px 100px;

      h6 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
        opacity: 0.8;
      }
    }

    .benefits-card {
      height: ${COUNTRY === 'me' ? '350px' : 'unset'};
    }
  }

  @media screen and (min-width: 1500px) {
    .benefits-section {
      padding: 120px 150px;

      h6 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
        opacity: 0.8;
      }
    }
  }

  @media screen and (min-width: 1601px) {
    .benefits-section {
      padding: 120px 200px;

      h6 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
        opacity: 0.8;
      }
    }
  }

  @media screen and (min-width: 2000px) {
    .benefits-section {
      padding: 120px 200px;
    }

    .benefits-card {
      max-width: 400px;
      width: 400px;

      h6 {
        font-size: 24px;
      }

      p {
        opacity: 0.8;
      }
    }
  }

  @media screen and (min-width: 2200px) {
    .benefits-card {
      max-width: 450px;
      width: 450px;
      height: ${COUNTRY === 'me' ? '550px' : 'unset'};

      img {
        width: 140px;
      }

      h6 {
        font-size: 40px;
      }

      p {
        font-size: 26px;
        opacity: 0.8;
      }
    }
  }
`;

export const BenefitsCardsWrapper = styled.div`
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.9) 16.93%, rgba(255, 255, 255, 0) 162.81%);
  /* backdrop-filter: blur(8px); */
  border-radius: 20px;

  .benefits-card {
    border-right: 1px solid rgba(0, 0, 0, 0.3);

    :last-child {
      border: none;
    }
  }

  @media screen and (max-width: 768px) {
    .benefits-card {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
      padding: 20px;
      max-width: 100%;
      width: 100%;

      :last-of-type {
        border: none;
      }
    }
  }
`;
